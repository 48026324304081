import React, {useState} from "react";
import styled from "styled-components";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from "@material-ui/core/Button";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConverterDateTime from "../../elements/converters/ConverterDateTime";
import getClassNameForExtension from "font-awesome-filetypes";

const BulletinWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const BulletinName = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 18px;
`
const BulletinDescription = styled.div``
const BulletinDate = styled.div`
  display: flex;  
  justify-content: space-between;
`
const FilesTitle = styled.div`
  text-align: center;
  font-size: 16px;
`
const FilesTable = styled.div`
  display: grid;
  grid-template-columns: 5% 75% 15% 5%;
  margin: 10px 0;
  border-bottom: 1px solid #CCCCCC;
  border-right: 1px solid #CCCCCC;
  border-left: 1px solid #CCCCCC;
`
const FilesCell = styled.div`
  padding: 4px;
  border-top: 1px solid #CCCCCC;
`
const QuestionName = styled.div``
const QuestionWrapper = styled.div`
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 6px;
  border: 1px solid white;
  transition-property: border;
  transition-duration: 150ms;
  ${({ error }) => error &&`
    border: 1px solid #f50057;
  `}
`
const SubmitWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const initErrors = (questions) => {
    let init_errors = {}
    questions.map((question) => {
        init_errors[question.id] = false
        }
    )
    return init_errors
}

export default function Bulletin(props) {
    const [voted, setVoted] = useState(props.voted)
    const [errors, setErrors] = useState(initErrors(props.bulletin.bulletins_questions))
    const [form_values, setFormValues] = useState({})

    const docs = props.bulletin.docs.map((file, index) => (
        <React.Fragment key={index}>
            <FilesCell>
                {index + 1}
            </FilesCell>
            <FilesCell>
                <i className={"fas " + getClassNameForExtension(file.filename.split('.').pop())}></i>&nbsp;
                {file.filename}
            </FilesCell>
            <FilesCell>
                {Math.round(file.size / 10.24) / 100} КБ
            </FilesCell>
            <FilesCell>
                <a href={file.url} download>
                    <i className="fas fa-download text-success"></i>
                </a>
            </FilesCell>
        </React.Fragment>
    ))

    function formChange(e) {
        setFormValues({...form_values, [e.target.name]: e.target.value})
        setErrors((errors) => ({...errors, [e.target.name]: false}))
    }

    function toVote(){
        let new_errors = {}
        for (let key in errors){
            new_errors[key] = !form_values.hasOwnProperty(key);
        }
        if (Object.values(new_errors).includes(true)){
            toast.error("Вы ответили не на все вопросы в бюллетени")
            setErrors(() =>(new_errors))
        } else {
            createVote()
        }
    }

    function createVote(){
        let vote_data = {
            vote: {
                user_id: props.user_id,
                bulletin_id: props.bulletin.id,
                questions_and_answers: form_values
            }
        }
        fetch('/bulletins', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': props.token
            },
            body: JSON.stringify(vote_data)
        }).then(response => {
            if (!response.ok) {
                toast.error("Возникла ошибка при голосовании");
            } else {
                setVoted(true)
                toast.success("Ваш голос учтен. Спасибо!");
            }
        }).catch((e) => {
            toast.error("Возникла ошибка при голосовании");
        });
    }

    return (
        <BulletinWrapper>
            <BulletinName>{props.bulletin.name}</BulletinName>
            {(props.can_vote && !voted) &&
                <>
            <BulletinDescription dangerouslySetInnerHTML={{__html: props.bulletin.description}}/>
                    <BulletinDate>
                        <div><span>Начало голосования: </span><ConverterDateTime datetime={props.bulletin.start_date}/></div>
                        <div><span>Завершение голосования: </span><ConverterDateTime datetime={props.bulletin.stop_date}/></div>
                    </BulletinDate>
                    <div>
                        {props.bulletin.docs.length > 0 &&
                        <>
                            <FilesTitle>Документы</FilesTitle>
                            <FilesTable>
                                <FilesCell>№</FilesCell>
                                <FilesCell>Название</FilesCell>
                                <FilesCell align="right">Размер</FilesCell>
                                <FilesCell/>
                                {docs}
                            </FilesTable>
                        </>
                        }
                    </div>
                <FormControl component="fieldset"
                             onChange={formChange}>
                    {props.bulletin.bulletins_questions.map((question, index) =>
                        <React.Fragment key={question.id}>
                            <QuestionWrapper error={errors[question.id]}>
                            {props.bulletin.bulletins_questions.length > 1 && <strong>Вопрос № {index + 1}</strong>}
                            <QuestionName dangerouslySetInnerHTML={{__html: question.name}}/>
                            <RadioGroup name={question.id.toString()} value={form_values[question.id.toString()] || ''}>
                                {
                                    question.bulletins_answers.map((answer) =>
                                        <FormControlLabel key={answer.id} value={answer.id.toString()}
                                                          control={<Radio/>}
                                                          label={answer.name}/>
                                    )
                                }
                            </RadioGroup>
                            </QuestionWrapper>
                        </React.Fragment>
                    )}
                </FormControl>
            <SubmitWrapper>
                <Button variant="outlined" color="primary" onClick={toVote}>
                    <i className="fas fa-check">&nbsp;</i> Проголосовать
                </Button>
            </SubmitWrapper>
                </>
            }
            {voted &&
                <p>Спасибо! Ваш голос учтен.</p>
            }
            <ToastContainer/>
        </BulletinWrapper>
    );
}
