import React from 'react';
import {Viewer, Worker, LocalizationMap, LocalizationProvider} from '@react-pdf-viewer/core';

// import '@react-pdf-viewer/core/lib/styles/index.css';
import '../../../libs/react-pdf-viewer//core/lib/styles/index.css';
// @ts-ignore
import ru_RU from "../../../libs/react-pdf-viewer/languages/ru_RU.json";

interface PDFViewerSimple {
    fileUrl: string;
}

const PDFViewer: React.FC<PDFViewerSimple> = ({fileUrl}) => {
    return (
        <LocalizationProvider localization={(ru_RU as any) as LocalizationMap}>
            {
                (_) => (
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                                <Viewer
                                    fileUrl={fileUrl}
                                />
                            </Worker>
                )
            }
        </LocalizationProvider>
    );
};

export default PDFViewer;