import ReactOnRails from 'react-on-rails';

import GalleryShow from '../../components/old/gallery/GalleryShow';
import Bulletin from '../../components/front/bulletins/Bulletin';
import Registration from '../../components/front/Registration';
import ModalPDFViewerFilename from "../../components/elements/pdf/ModalPDFViewerFilename";
import Attachments from "../../components/elements/files/Attachments";
import QrCode from "../../components/elements/qr_code/qr_code";

ReactOnRails.register({
    Attachments, GalleryShow, Registration, ModalPDFViewerFilename, Bulletin, QrCode
});
