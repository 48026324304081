import React from "react"

export default function ConverterDateTime(props) {
    let html_date = new Date(props.datetime)
    let date = new Intl.DateTimeFormat('ru-RU').format(html_date)
    let time = new Intl.DateTimeFormat('ru-RU', {hour: "numeric", minute: "numeric"}).format(html_date)
    return (
        <span><i className="far fa-calendar-alt"></i> {date} <i className="far fa-clock"></i> {time}</span>
    )
}

