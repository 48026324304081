import React from "react"

export default function ConverterIcon(props) {
    let content_type = props.content_type
    switch(content_type){
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return (
                <>
                    <i className="fas fa-file-word"></i>&nbsp;
                </>
            )
        case 'image/jpeg':
        case 'image/jpeg':
        case 'image/gif':
            return (
                <>
                    <i className="fas fa-file-image"></i>&nbsp;
                </>
            )
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'text/csv':
            return (
                <>
                    <i className="fas fa-file-excel"></i>&nbsp;
                </>
            )
        case 'application/zip':
            return (
                <>
                    <i className="fas fa-file-archive"></i>&nbsp;
                </>
            )
        case 'video/mp4':
            return (
                <>
                    <i className="fas fa-file-video"></i>&nbsp;
                </>
            )
        default:
            return (
                <>
                <i className="fas fa-file"></i>&nbsp;
                </>
            )
    }

}
