import React from "react"

class ConverterDate extends React.Component {
    render() {
        let html_date = new Date(this.props.datetime)
        let date = new Intl.DateTimeFormat('ru-RU').format(html_date)
        return (
            <span><i className="far fa-calendar-alt"></i> {date}</span>
        );
    }
}

export default ConverterDate
