import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ConverterDate from '../converters/ConverterDate'
import styled from 'styled-components';

const FileItem = styled.div`
  display: grid;
  grid-template-columns: 60% 20% 10%;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  &:hover {
    background-color: #eaeaea;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    grid-template-columns: 70% 30%;
  }
`
const FileName = styled.div``
const FileDate = styled.div``
const FileSize = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`
const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 16px 24px;
`
const TitleTextWrapper = styled.div`
  display: block;
`
const ButtonCloseWrapper = styled.div`
  display: block;
`
const TitleText = styled.span`
  font-weight: bold;
`
const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
`
export default function AttachmentsVideo(props) {
    const filename = props.file.filename || ""
    const url = props.file.url || ""
    const created_at = props.file.created_at || ""
    const size = props.file.size || ""
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <FileItem onClick={handleClickOpen}>
                <FileName><i className="fas fa-file-video"></i>&nbsp;{filename}</FileName>
                <FileDate>
                    <ConverterDate datetime={created_at}/>
                </FileDate>
                <FileSize>{size}</FileSize>
            </FileItem>
            <Dialog
                fullWidth={true}
                maxWidth={"xl"}
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <Title>
                    <TitleTextWrapper>
                        <TitleText>{filename}</TitleText>
                    </TitleTextWrapper>
                    <ButtonCloseWrapper>
                        <Button onClick={handleClose} variant="contained" color="secondary">
                            Закрыть
                        </Button>
                    </ButtonCloseWrapper>
                </Title>
                <DialogContent>
                    <VideoWrapper>
                        <video src={url} autoPlay controls>
                            К сожалению, ваш браузер не поддерживает встроенные видео,
                            но не волнуйся, вы можете<a href={url}>скачать его</a>
                            и посмотреть в любимом видеоплеере!
                        </video>
                    </VideoWrapper>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
