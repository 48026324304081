import React from 'react';
import AttachmentsPDF from './AttachmentsPDF'
import styled from 'styled-components';
import ConverterDate from "../converters/ConverterDate";
import ConverterIcon from "../converters/ConverterIcon";
import AttachmentsImage from "./AttachmentsImage";
import AttachmentsVideo from "./AttachmentsVideo";


const BlockTitle = styled.div`
  text-align: center;
`
const FilesTitle = styled.div`
  display: grid;
  grid-template-columns: 60% 20% 10%;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 3px;
  text-decoration: none;
  color: #212529;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  font-weight: bold;
  @media (max-width: 768px) {
    grid-template-columns: 70% 30%;
  }
`
const FilesTitleName = styled.div``
const FilesTitleDate = styled.div``
const FilesTitleSize = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`

const FileItem = styled.a`
  display: grid;
  grid-template-columns: 60% 20% 10%;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 3px;
  text-decoration: none;
  color: #212529;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  &:hover {
    color: #212529;
    background-color: #eaeaea;
  }
`
export default function Attachments(props) {
    let files = props.files
    return (
        <>
            <BlockTitle><h3>Документы</h3></BlockTitle>
            <FilesTitle>
                <FilesTitleName><span>Имя файла</span></FilesTitleName>
                <FilesTitleDate><span>Дата</span></FilesTitleDate>
                <FilesTitleSize><span>Размер</span></FilesTitleSize>
            </FilesTitle>
            {
                files.map((file) => {
                        if (file.content_type == "application/pdf") {
                            return <React.Fragment key={file.id}>
                                <AttachmentsPDF file={file}/>
                            </React.Fragment>
                        } else if ((file.content_type == "image/jpeg") || (file.content_type == "image/png") || (file.content_type == "image/gif")) {
                            return <React.Fragment key={file.id}>
                                <AttachmentsImage file={file}/>
                            </React.Fragment>
                        } else if (file.content_type == "video/mp4") {
                            return <React.Fragment key={file.id}>
                                <AttachmentsVideo file={file}/>
                            </React.Fragment>
                        } else {
                            return <React.Fragment key={file.id}>
                                <FileItem href={file.url}>
                                    <div>
                                        <ConverterIcon content_type={file.content_type}/>
                                        {file.filename}</div>
                                    <div>
                                        <ConverterDate datetime={file.created_at}/>
                                    </div>
                                    <div>{file.size}</div>
                                </FileItem>
                            </React.Fragment>
                        }
                    }
                )}
        </>
    )
}
